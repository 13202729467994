import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from "gsap/CustomEase";
import Swiper from 'swiper/bundle';
import Lenis from '@studio-freight/lenis';
// import Swup from 'swup';
// import SwupDebugPlugin from '@swup/debug-plugin';
import Lottie from 'lottie-web';

/*-------------------------------------
 Lenis設定（スムーススクロール）
--------------------------------------*/
const lenis = new Lenis();
function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);


/*-------------------------------------
 スクロール時の処理
--------------------------------------*/
let startPos = 0;
let winScrollTop = 0;
let threshold = window.innerHeight;
window.addEventListener('scroll', () => {
  winScrollTop = window.scrollY;

  if (winScrollTop !== 0) {
    document.body.classList.add('is-scroll');
  } else {
    document.body.classList.remove('is-scroll');
  }

  if (winScrollTop >= startPos) {
    if (winScrollTop >= threshold) {
      document.body.classList.add('is-down');
    }
  } else {
    document.body.classList.remove('is-down');
  }
  startPos = winScrollTop;
});
window.addEventListener('resize', () => {
  threshold = window.innerHeight;
});

/*-------------------------------------
 GSAP ScrollTrigger
--------------------------------------*/
gsap.registerPlugin(ScrollTrigger);

function createScrollTrigger(selector, start, className, markers = false, pin = false) {
  const elements = document.querySelectorAll(selector);
  elements.forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      id: index + 1,
      start: start,
      toggleClass: { targets: el, className: className },
      once: true,
      markers: markers,
			pin: pin,
    });
  });
}

/*-------------------------------------
 Swup関連
--------------------------------------*/

/*
function updateBodyClass() {
  const mainElement = document.querySelector('main');
  if (mainElement) {
    const pageName = mainElement.dataset.pagename;
    document.body.className = pageName ? pageName : '';
  }
}

const swup = new Swup({
  plugins: [new SwupDebugPlugin()],
  linkSelector: 'a:not(.no-swup)',
});

function removeIsReadyClass() {
  document.body.classList.remove('is-ready');
}

function addIsReadyClass() {
  document.body.classList.add('is-ready');
}

function swupinit() {
  updateBodyClass();
  if (document.readyState === 'complete') {
    addIsReadyClass();
  } else {
    window.addEventListener('load', () => addIsReadyClass());
  }
}

*/
const staggerBezier = "M0,0 C0.126,0.382 0.322,0.759 0.5,0.9 0.824,1.157 0.973,1.091 1,1";

function staggerGsap() {
  // column
  if(document.querySelectorAll('#topColumn')) {
    const items = document.querySelectorAll('#topColumn .swiper-slide');
    gsap.set(items, {
      y: 50,
      opacity: 0
    });
    gsap.to(items, {
      y: 0,
      opacity: 1,
      duration: .3,
      ease: CustomEase.create("custom", staggerBezier),
      stagger: {
        each: .15,
        from: 'start'
      },
      scrollTrigger: {
        trigger: items,
        start: 'top 70%',
      }
    });
  }

  // newslist
  if(document.querySelectorAll('.newslist')) {
    const items = document.querySelectorAll('.newslist li');
    gsap.set(items, {
      y: 20,
      opacity: 0
    });
    gsap.to(items, {
      y: 0,
      opacity: 1,
      duration: .5,
      ease: CustomEase.create("custom", staggerBezier),
      stagger: {
        each: .08,
        from: 'start'
      },
      scrollTrigger: {
        trigger: items,
        start: 'top 70%',
      }
    });
  }

  // footer
  if(document.querySelectorAll('footer .main')) {
    const items = document.querySelectorAll('footer .main > li');
    gsap.set(items, {
      y: 30,
      opacity: 0
    });
    gsap.to(items, {
      y: 0,
      opacity: 1,
      duration: .5,
      ease: CustomEase.create("custom", staggerBezier),
      stagger: {
        each: .05,
        from: 'start'
      },
      scrollTrigger: {
        trigger: items,
        start: 'top 70%',
      }
    });
  }
}


function topSolutions() {
  /*
	const wrapper = document.querySelector('#solutionsWrapper');
	const container = document.querySelector('.m_top_solutions .inner');
	const items = document.querySelectorAll('.m_top_solutions .unit');
	gsap.set(container,{
		width: "300vw",
	 });
	 console.log(items[0].offsetWidth);
	 const move = items[0].offsetWidth * 2.08;
	 console.log(move);
	gsap.to(container, {
		x: "-" + 200 + "vw",
		ease: "none",
		scrollTrigger: {
			trigger: wrapper,
			start: "center center",
			end: "bottom top",
			pin: true,
			scrub: true,
			anticipatePin: 1,
			markers: true,
		}
	 });
   */
}

/*-------------------------------------
 Swup Hooks
--------------------------------------*/

/*
swup.hooks.on('visit:start', () => {
  removeIsReadyClass();
  updateBodyClass();
});

swup.hooks.on('animation:in:end', () => {
  addIsReadyClass();
});

if (document.readyState === 'complete') {
  swupinit();
} else {
  document.addEventListener('DOMContentLoaded', () => swupinit());
}

swup.hooks.on('page:view', () => {
  swupinit();
});

swup.hooks.on('visit:start', () => {
  ScrollTrigger.getAll().forEach((t) => t.kill(false));
  ScrollTrigger.refresh();
});

swup.hooks.on('content:replace', () => {
  updateBodyClass();
  initSwiper();
  createScrollTrigger('.fadeUp','top 75%','is-active');
  createScrollTrigger('.roll-text','top 75%','is-active');
  createScrollTrigger('.m_top_works .unit','top 50%','is-active', false);
	rollText();
	topSolutions();
  staggerGsap();
  initializeIntro();
});
*/

/*-------------------------------------
 メニュー開閉・トグル関連
--------------------------------------*/
document.addEventListener('DOMContentLoaded', () => {
  initSwiper();
  createScrollTrigger('.fadeUp','top 75%','is-active');
  createScrollTrigger('.roll-text','top 75%','is-active');
  createScrollTrigger('.m_top_works .unit','top 50%','is-active', false);
  //toppageVisited();
	rollText();
	topSolutions();
  staggerGsap();
  initializeIntro();
  document.body.classList.add('is-ready');
  

  const menuButton = document.getElementById('menuButton');
  const gnav = document.getElementById('gnav');
  let isMenuOpen = false;
  let scrollPos = 0;

  menuButton.addEventListener('click', () => {
    menuButton.classList.toggle('show');
    gnav.classList.toggle('show');
    if (!isMenuOpen) {
      scrollPos = window.scrollY;
      document.body.classList.add('fixed');
      document.body.style.top = `-${scrollPos}px`;
      isMenuOpen = true;
    } else {
      closeMenu();
    }
  });

  function closeMenu() {
    menuButton.classList.remove('show');
    gnav.classList.remove('show');
    document.body.classList.remove('fixed');
    document.body.style.top = '0';
    window.scrollTo(0, scrollPos);
    isMenuOpen = false;
  }

  Array.from(gnav.querySelectorAll('a')).forEach(link => {
    link.addEventListener('click', closeMenu);
  });

  // メニュー以外をクリックしたら閉じる
  document.addEventListener('click', (e) => {
    if (!gnav.contains(e.target) && !menuButton.contains(e.target) && isMenuOpen) {
      closeMenu();
    }
  });

  // リサイズ対応
  function handleResize() {
    if (window.innerWidth >= 768) {
      document.body.classList.remove('fixed');
      document.body.style.top = '0';
    }
  }
  window.addEventListener('load', handleResize);
  window.addEventListener('resize', handleResize);

  /*-------------------------------------
   ページトップボタン
  --------------------------------------*/
  const pagetop = document.getElementById('pagetop');
  if (pagetop) {
    pagetop.addEventListener('click', () => {
      lenis.scrollTo(0);
    });
  }

  /*-------------------------------------
   特定リンクのアンカー移動
  --------------------------------------*/
  const gnavAnchors = document.querySelectorAll('.top .gnav-anchor');
  gnavAnchors.forEach(anchor => {
    anchor.addEventListener('click', (e) => {
      e.preventDefault();
      const href = anchor.getAttribute('href');
      const id = href.replace('/', '');
      lenis.scrollTo(id);
    });
  });

  const anchorTarget = document.querySelector('.type_anchor .inner');
  if (anchorTarget) {
    const titles = document.querySelectorAll('.anchor');
    let anchorText = '';
    
    titles.forEach(title => {
      const id = title.getAttribute('id');
      const text = title.nextElementSibling.textContent;
      anchorText += `<li><a href="#${id}">${text}</a></li>`;
    });
    anchorTarget.innerHTML = `<ul>${anchorText}</ul>`;
    
  }

  const articleWrapper = document.querySelector('.article-wrapper');
  
  if (articleWrapper) {
    const articleContents = document.querySelector('.article-contents');
    const navAnchors = document.querySelector('.article-nav .anchors');
    
    if (articleContents && navAnchors) {
      const anchors = articleContents.querySelectorAll('a.anchor');
      let anchorLinks = '';
      console.log(anchors);
      
      anchors.forEach(anchor => {
        const id = anchor.getAttribute('id');
        const text = anchor.getAttribute('data-title');
        console.log(id, text);
        if (!text) return;
        anchorLinks += `<li><a href="#${id}">${text}</a></li>`;
      });
      navAnchors.innerHTML = `${anchorLinks}`;
    }
  }

  // まずセレクタにマッチする要素が存在するか確認
  const anchors = document.querySelectorAll('a[href^="#"], a[href^="/#"], .anchors a');

  anchors.forEach(anchor => {
    
    anchor.addEventListener('click', function(event) {
      console.log('Anchor clicked:', this.getAttribute('href'));
      
      const href = this.getAttribute('href');
      if (!href || href.startsWith('mailto:') || !href.includes('#')) return;
      
      const targetId = href.includes('#') ? href.split('#')[1] : null;
      if (!targetId) return;
      
      const targetElement = document.getElementById(targetId);
      console.log('Target element:', targetId, targetElement);
      
      if (targetElement) {
        event.preventDefault();
        const nextElement = targetElement.nextElementSibling;
        if (nextElement) {
          const rectTop = nextElement.getBoundingClientRect().top + window.scrollY;
          // ウィンドウ幅に応じたオフセットの計算
          const offset = window.innerWidth < 768 
            ? (80 / 390) * window.innerWidth 
            : (120 / 1440) * Math.min(window.innerWidth, 1440);
          const targetPosition = rectTop - offset;
          console.log('.m_boxlistの上端位置:', rectTop);
          console.log('offset:', offset);
          console.log('スクロール先位置:', targetPosition);
          lenis.scrollTo(targetPosition);
        }
      }
    });
  });

  const faqElements = document.querySelectorAll('.type_faq');
  if (faqElements.length) {
    faqElements.forEach(faq => {
      const questions = faq.querySelectorAll('.q');
      questions.forEach(question => {
        question.addEventListener('click', () => {
          question.classList.toggle('active');
          const nextElement = question.nextElementSibling;
          
          if (nextElement.style.display === 'none' || !nextElement.style.display) {
            nextElement.style.display = 'block';
          } else {
            nextElement.style.display = 'none';
          }
        });
      });
    });
  }
});

/*-------------------------------------
 Swiper初期化
--------------------------------------*/
function initSwiper() {
  const swiper = new Swiper('#topColumn', {
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.columnNext',
      prevEl: '.columnPrev',
    },
  });
}


/*-------------------------------------
 roll text
--------------------------------------*/

function rollText() {
	document.querySelectorAll('.roll-text .parent').forEach(button => button.innerHTML = '<span class="child"><i>' + button.textContent.trim().split('').join('</i><i>') + '</i></span>');
	document.querySelectorAll('#intro .parent').forEach(button => button.innerHTML = '<span class="child"><i>' + button.textContent.trim().split('').join('</i><i>') + '</i></span>');
}

/*-------------------------------------
 lottie
--------------------------------------*/
function loadLottie() {
  Lottie.loadAnimation({
    container: document.getElementById('lottie-branding'),
    path: "/lottie/branding.json",
    loop: true,
    autoplay: true,
  });
  Lottie.loadAnimation({
    container: document.getElementById('lottie-teambuilding'),
    path: "/lottie/teambuilding.json",
    loop: true,
    autoplay: true,
  });
  Lottie.loadAnimation({
    container: document.getElementById('lottie-digitalshift'),
    path: "/lottie/digitalshift.json",
    loop: true,
    autoplay: true,
  });
}

function loadOtherLottie() {
  if (document.querySelector('main').getAttribute('data-pagename') !== 'top') {
    Lottie.loadAnimation({
      container: document.getElementById('lottie-branding'),
      path: "/lottie/branding.json",
      loop: true,
      autoplay: true,
    });
    Lottie.loadAnimation({
      container: document.getElementById('lottie-branding-b'),
      path: "/lottie/branding_b.json",
      loop: true,
      autoplay: true,
    });
    Lottie.loadAnimation({
      container: document.getElementById('lottie-digitalshift'),
      path: "/lottie/digitalshift.json",
      loop: true,
      autoplay: true,
    });
    Lottie.loadAnimation({
      container: document.getElementById('lottie-digitalshift-b'),
      path: "/lottie/digitalshift_b.json",
      loop: true,
      autoplay: true,
    });
    Lottie.loadAnimation({
      container: document.getElementById('lottie-teambuilding'),
      path: "/lottie/teambuilding.json",
      loop: true,
      autoplay: true,
    });
    Lottie.loadAnimation({
      container: document.getElementById('lottie-teambuilding-b'),
      path: "/lottie/teambuilding_b.json",
      loop: true,
      autoplay: true,
    });
  }
}

loadOtherLottie();

function initializeIntro() {
  const introElement = document.getElementById("intro");
  const worksTitleElement = document.getElementById("workstitle");
  if (!introElement) return;

  if (sessionStorage.getItem('isToppageVisited') === 'true') {
    // 2回目以降
      loadLottie();
      introElement.classList.add("is-finished");
      introElement.classList.add("is-ready");
      introElement.classList.add("is-not-first");
      setTimeout(() => {
        worksTitleElement.classList.add("is-active");
      }, 500);
  } else {
    // 初回訪問
    sessionStorage.setItem('isToppageVisited', 'true');
    document.body.classList.add("is-fixed");
    Promise.all([
      Lottie.loadAnimation({
        container: document.getElementById('lottie-introtop'),
        path: "/lottie/introtop.json",
        loop: true,
        autoplay: true,
      }),
      Lottie.loadAnimation({
        container: document.getElementById('lottie-introbottom'),
        path: "/lottie/introbottom.json",
        loop: true,
        autoplay: true,
      }),
    ]).then(() => {
      introElement.classList.add("is-ready");
    });
    loadLottie();
    
    /*
    const leadInnerEl = document.querySelector("#intro .lead");
    const innerEl = document.querySelector("#intro .leadinner");
    const leadInnerHeight = leadInnerEl.offsetHeight;
    const innerHeight = innerEl.offsetHeight;
    const shorterHeight = Math.min(leadInnerHeight, innerHeight);
    document.documentElement.style.setProperty("--lead-height", `${shorterHeight}px`);
    */

    let clickHandlerExecuted = false;

    const clickHandler = () => {
      if (clickHandlerExecuted) return;
      clickHandlerExecuted = true;

      document.body.classList.remove("is-fixed");
      introElement.classList.add("is-finished");
      lenis.scrollTo(0, {
        duration: 1,
      });
      setTimeout(() => {
        worksTitleElement.classList.add("is-active");
      }, 500);
      // スクロールを許可
      document.body.style.overflow = "auto";
      document.body.removeEventListener("touchmove", preventScroll, { passive: false });
    };

    const preventScroll = (e) => {
      e.preventDefault();
    };

    // マウスやスワイプによるスクロールを禁止
    document.body.style.overflow = "hidden";
    document.body.addEventListener("touchmove", preventScroll, { passive: false });

    introElement.addEventListener("click", clickHandler);
    
    // DOMロード後9000ms経過したら、clickイベントを強制的に発火
    setTimeout(() => {
      clickHandler();
    }, 9000);
  }
}

/*-------------------------------------
 フッター固定ボタンのスクロール処理
--------------------------------------*/
let fixedFooterStartPos = 0;
const fixedFooterButton = document.getElementById('fixedFooterButton');

// jQueryを使わないES6の書き方に変更
window.addEventListener('scroll', () => {
  const winScrollTop = window.scrollY;
  const documentHeight = document.documentElement.scrollHeight;
  const footerHeight = document.querySelector('footer').offsetHeight;
  const windowHeight = window.innerHeight;
  
  if (fixedFooterButton) {
    if (winScrollTop > 500 && winScrollTop < documentHeight - footerHeight - windowHeight) {
      fixedFooterButton.classList.add('is-scroll');
    } else {
      fixedFooterButton.classList.remove('is-scroll');
    }
  }
  fixedFooterStartPos = winScrollTop;
});